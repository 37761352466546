import React from 'react'
// import html2canvas from 'html2canvas'
// import { ReactComponent as ShareIcon } from './share-icon.svg'

import styles from './Results.module.css'

function roundNumber(a) {
  return Math.round(a.toFixed(0) * 100) / 100;
}

function percDiff(a, b) {
  const diff = 100 * Math.abs( (a - b) / ( (a + b) / 2 ) )
  return roundNumber(diff)
}


export default function Results({ data }) {
  // if (!Object.keys(data).length) {
  //   data = {
  //     'article1': {
  //       bionic: 30,
  //       literata: 10
  //     },
  //     'article2': {
  //       bionic: 30,
  //       literata: 10
  //     }
  //   }
  // }

  const speedArticle1Bionic = roundNumber(data.article1.bionic)
  const speedArticle1Literata = roundNumber(data.article1.literata)

  const speedArticle2Bionic = roundNumber(data.article2.bionic)
  const speedArticle2Literata = roundNumber(data.article2.literata)

  const averageSpeedBionic = roundNumber((speedArticle1Bionic + speedArticle2Bionic) / 2)
  const averageSpeedLiterata = roundNumber((speedArticle1Literata + speedArticle2Literata) / 2)

  const percentageSpeedDiff = percDiff(averageSpeedBionic, averageSpeedLiterata)
  const fasterFont = averageSpeedLiterata > averageSpeedBionic ? 'Literata' : 'Bionic Reading'

  // function shareResults() {
  //   const baseUrl = `https://twitter.com/intent/tweet?text=`
  //   const text = encodeURIComponent(`I read ${percentageSpeedDiff}% faster with ${fasterFont}. Get your own results at https://speed.readwise.io`)
  //   const url = `${baseUrl}${text}`
  //   window.open(url, '_blank')
  //   html2canvas(document.querySelector(`.${styles.results}`)).then(canvas => {
  //     const dataURL = canvas.toDataURL();
  //     console.log(dataURL);
  //   });
  // }

  return (
    <div className={styles.resultsWrapper}>
      <div className={styles.results}>
        <div className={styles.mainHeader}>
          <h1>Your results</h1>
          <a href="https://readwise.io" className={styles.logo} target="_blank" rel="noopener noreferrer"><img alt="Readwise logo" width="37px" height="37px" src="https://readwise-assets.s3.amazonaws.com/static/images/favicons/apple-touch-icon.b0a6183eb2e0.png"/></a>
        </div>

        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.header}>
              Typeface
            </div>
            <div className={styles.content}>
              <div className={styles.cell}>
                <b>Bionic Reading</b>
              </div>
              <div className={styles.cell}>
                <b>Literata</b>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.header}>
              Article 1
            </div>
            <div className={styles.content}>
              <div className={styles.cell}>
                {speedArticle1Bionic} WPM
              </div>
              <div className={styles.cell}>
                {speedArticle1Literata} WPM
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.header}>
              Article 2
            </div>
            <div className={styles.content}>
              <div className={styles.cell}>
                {speedArticle2Bionic} WPM
              </div>
              <div className={styles.cell}>
                {speedArticle2Literata} WPM
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.header}>
              Average Speed
            </div>
            <div className={styles.content}>
              <div className={styles.cell} style={{ fontWeight: 600 }}>
                <b>{averageSpeedBionic} WPM</b>
              </div>
              <div className={styles.cell} style={{ fontWeight: 600 }}>
                <b>{averageSpeedLiterata} WPM</b>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer} style={{ fontWeight: 600 }}>
          You read {percentageSpeedDiff}% faster with {fasterFont}
          {/* <span className={styles.share} onClick={shareResults}>
            <ShareIcon/>
          </span> */}
        </div>
      </div>
    </div>
  )
}
