
import React, {useState, useEffect} from 'react';
import Article from './components/Article';
import Results from './components/Results';
import getArticlesData from './utils/getArticlesData'
import { v4 as uuidv4 } from 'uuid';

function getRandomBool() {
  return Math.random() < 0.5
}

function getIsMobile() {
  return window.matchMedia('(max-width: 700px)').matches
}

function App() {
  const [ip, setIp] = useState(null)
  const [isMobile] = useState(getIsMobile())
  const [randomUserId] = useState(uuidv4())
  const [articles, setArticles] = useState(null);
  const [step, setStep] = useState('1a');
  const [is1aBionicReading] = useState(getRandomBool());
  const [results, setResults] = useState({});

  useEffect(() => {
    async function getIp() {
      const response = await fetch('https://ipgeolocation.abstractapi.com/v1/?api_key=fd07a541ae644beaa88df09d6073e93a')
      const data = await response.json()
      setIp(data.ip_address)
    }

    try {
      getIp()
    } catch (error) {

    }
  }, [])

  useEffect(() => {
    async function loadArticles() {
      const _articles = await getArticlesData();
      setArticles(_articles)
    }

    loadArticles()
  }, [])

  if (!articles) {
    return null;
  }

  return (
    <div className="App">
      {step === '1a' && (
        <Article
          isFirstArticle
          article={articles['1a']}
          isBionicReading={is1aBionicReading}
          setResults={setResults}
          goToNextStep={() => setStep('1b')}
          randomUserId={randomUserId}
          isMobile={isMobile}
          ip={ip}
        />
      )}

      {step === '1b' && (
        <Article
          article={articles['1b']}
          isBionicReading={!is1aBionicReading}
          setResults={setResults}
          goToNextStep={() => setStep('2a')}
          randomUserId={randomUserId}
          isMobile={isMobile}
          ip={ip}
        />
      )}

      {step === '2a' && (
        <Article
          article={articles['2a']}
          isBionicReading={!is1aBionicReading}
          setResults={setResults}
          goToNextStep={() => setStep('2b')}
          randomUserId={randomUserId}
          isMobile={isMobile}
          ip={ip}
        />
      )}

      {step === '2b' && (
        <Article
          article={articles['2b']}
          isBionicReading={is1aBionicReading}
          setResults={setResults}
          goToNextStep={() => setStep('results')}
          randomUserId={randomUserId}
          isMobile={isMobile}
          ip={ip}
        />
      )}

      {step === 'results' && (
        <Results data={results}/>
      )}
    </div>
  );
}

export default App;
