async function getHtmlFromFile(fileName) {
  return fetch(`/articles/${fileName}.html`).then(async result => {
    const html = await result.text()
    return html
  })
}

export default async function getArticlesData() {
  const resolvedPromises = await Promise.all([
    getHtmlFromFile('1a'),
    getHtmlFromFile('1a-bionic'),
    getHtmlFromFile('1b'),
    getHtmlFromFile('1b-bionic'),

    getHtmlFromFile('2a'),
    getHtmlFromFile('2a-bionic'),
    getHtmlFromFile('2b'),
    getHtmlFromFile('2b-bionic'),
  ])

  const [
    art1a,
    art1aBionic,
    art1b,
    art1bBionic,
    art2a,
    art2aBionic,
    art2b,
    art2bBionic
  ] = resolvedPromises

  return {
    '1a': {
      id: '1a',
      title: 'Organic startup ideas - Part 1',
      html: art1a,
      bionicHtml: art1aBionic,
      typeformId: 'ubT3iWqO',
      wordCount: 435,
      url: 'http://www.paulgraham.com/organic.html'
    },
    '1b': {
      id: '1b',
      title: 'Organic startup ideas - Part 2',
      html: art1b,
      bionicHtml: art1bBionic,
      typeformId: 'mW4VwReE',
      wordCount: 439,
      url: 'http://www.paulgraham.com/organic.html'
    },
    '2a': {
      id: '2a',
      title: 'The top idea in your mind - Part 1',
      html: art2a,
      bionicHtml: art2aBionic,
      typeformId: 'I7IehcIG',
      wordCount: 546,
      url: 'http://www.paulgraham.com/top.html'
    },
    '2b': {
      id: '2b',
      title: 'The top idea in your mind - Part 2',
      html: art2b,
      bionicHtml: art2bBionic,
      typeformId: 'QgzyqtMx',
      wordCount: 483,
      url: 'http://www.paulgraham.com/top.html'
    },
  }
}
