import React, {useState} from 'react'
import { Widget } from '@typeform/embed-react'

import styles from './Article.module.css'

export default function ArticleSection({ ip, isMobile, randomUserId, article, isBionicReading, goToNextStep, setResults }) {
  const [timeStart, setTimeStart] = useState(null)
  const [timeEnd, setTimeEnd] = useState(null)

  function getTime() {
    return new Date().getTime()
  }

  function onStartReading() {
    setTimeStart(getTime())
  }

  function onFinishReading() {
    setTimeEnd(getTime())
  }

  function getReadingTime() {
    return (timeEnd - timeStart) / 1000
  }

  function onFinish() {
    const readingTimeInMin = getReadingTime() / 60;
    const words = article.wordCount;

    setResults(results => {
      const isFirstArticle = article.id === '1a' || article.id === '1b'
      const key = isFirstArticle ? 'article1' : 'article2'
      const typefaceKey = isBionicReading ? 'bionic' : 'literata'
      return {
        ...results,
        [key]: {
          ...results[key],
          [typefaceKey]: Math.round(words / readingTimeInMin)
        }
      }
    })

    goToNextStep()
  }

  const showInstructions = !timeStart && !timeEnd;
  const showArticle = timeStart && !timeEnd;
  const showQuizz = timeStart && timeEnd;
  const typeface = isBionicReading ? 'bionic' : 'literata';

  return (
    <div>
      {showInstructions && <Instructions articleId={article.id} onStartReading={onStartReading}/>}
      {showArticle && <Article {...{ article, isBionicReading, onFinishReading }}/>}
      {showQuizz && (
        <Quizz
          typeformId={article.typeformId}
          typeface={typeface}
          readingTime={getReadingTime()}
          wordCount={article.wordCount}
          randomUserId={randomUserId}
          isMobile={isMobile}
          ip={ip}
          onFinish={onFinish}
        />
      )}
    </div>
  )
}

function Instructions({ onStartReading, articleId }) {
  return (
    <div className={styles.instructions}>
      <div className={styles.left}>
        <a href="https://readwise.io" target="_blank" rel="noopener noreferrer"><img alt="Readwise logo" width="37px" height="37px" src="https://readwise-assets.s3.amazonaws.com/static/images/favicons/apple-touch-icon.b0a6183eb2e0.png"/></a>
      </div>
      <div className={styles.right}>
        {articleId === '1a' && (
          <>
            <h1>Does Bionic Reading actually help you read faster with no loss of comprehension? Help us find out!</h1>
            <p>When you click the button below, you'll begin reading the first of two Paul Graham essays, each approximately 5 minutes in length.</p>
            <p>A timer will run in the background so please only tap <b>Start reading</b> when you're actually ready to read.</p>
            <p>When you reach the end of each half, there will be a <b>Finished reading</b> button that will stop the timer.</p>
            <p>You'll then be asked three multiple choice questions to control for reading comprehension.</p>
            <p>Please read at your ordinary pace and note that highlighting has been disabled in order to focus solely on reading speed.</p>
          </>
        )}

        {articleId === '1b' && (
          <>
            <h1>You're 25% done!</h1>
            <p>Click <b>Start reading</b> below when you're ready to begin part 2 of the first Paul Graham essay.</p>
          </>
        )}

        {articleId === '2a' && (
          <>
            <h1>Halfway there! Keep going to see your results.</h1>
            <p>Click <b>Start reading</b> below when you're ready to begin part 1 of the second Paul Graham essay.</p>
          </>
        )}

        {articleId === '2b' && (
          <>
            <h1>One last section!</h1>
            <p>Click <b>Start reading</b> below when you're ready to begin part 2 of the second Paul Graham essay. You'll then see your results.</p>
          </>
        )}
        <button className='button' onClick={onStartReading}>Start reading</button>
      </div>
    </div>
  )
}

export function Article({ article, isBionicReading, onFinishReading }) {
  const __html = isBionicReading ? article.bionicHtml : article.html;
  const style = isBionicReading ? styles.bionic : styles.literata;

  return (
    <div id="document-text-content" className={[style, styles.articleWrapper, isBionicReading ? '' : 'literata' ].join(' ')}>
      <h1>{article.title}</h1>
      <div dangerouslySetInnerHTML={{ __html }} />
      <button className='button' style={{ margin: 'auto' }} onClick={onFinishReading}>Finish reading</button>
    </div>
  )
}

export function Quizz({ ip, isMobile, randomUserId, typeformId, typeface, readingTime, wordCount, onFinish }) {
  // const reading_speed = (readingTime / wordCount) / 60;
  const hidden = {
    user_id: randomUserId,
    typeface,
    reading_time: readingTime,
    platform: isMobile ? 'mobile' : 'desktop',
    ip_address: ip,
  }

  // // TODO REMOVE
  // console.log(hidden);

  // React.useEffect(() => {
  //   onFinish()
  // }, [onFinish])

  // return null
  // // TODO REMOVE

  return (
    <div>
      <Widget
        id={typeformId}
        style={{ width: '100%', height: '100vh' }}
        hidden={hidden}
        onSubmit={e => {
          console.log(e)
          onFinish()
        }}
      />
    </div>
  )
}
